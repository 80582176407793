const jsonToCsv = (json: unknown[]): string | null => {
  const arrData = typeof json !== 'object' ? JSON.parse(json) : json;

  let csv = '';
  let row = '';

  const topKeys = Object.keys(arrData[0]);
  for (let i = 0; i < topKeys.length; i += 1) {
    row += `${topKeys[i]},`;
  }
  row = row.slice(0, -1);

  csv += `${row}\r\n`;

  for (let i = 0; i < arrData.length; i += 1) {
    row = '';

    const keys = Object.keys(arrData[i]);
    for (let j = 0; j < keys.length; j += 1) {
      row += `"${arrData[i][keys[j]]}",`;
    }
    row.slice(0, row.length - 1);
    csv += `${row}\r\n`;
  }

  if (csv === '') {
    return null;
  }
  return csv;
};

export default jsonToCsv;
