import axios from 'axios';
import jsonToCsv from './jsonToCsv';

interface IData {
  time: string;
  temperature: number | null;
  humidity: number | null;
  co2: number | null;
  ec: number | null;
  ph: number | null;
  waterTemperature: number | null;
}
const getData = async (
  smartFarmID: number,
  igDeviceID: string,
  fromTime: Date,
  endTime: Date,
  fileName?: string
): Promise<void> => {
  const result: IData[] = [];
  let flag: Record<string, boolean> = {};
  fromTime.setDate(fromTime.getDate() + 1);
  fromTime.setUTCHours(-9);
  fromTime.setMinutes(0);
  fromTime.setSeconds(0);

  endTime.setUTCHours(14);
  endTime.setMinutes(59);
  endTime.setSeconds(59);

  const accessToken = await axios
    .post('https://iot-api.sangsang.farm/v2/auth/sign-in', {
      id: 'admin',
      password: 'imggd12!@#',
    })
    .then((response) => response.data.data.accessToken);

  const envData = await axios
    .get(
      `https://iot-api.sangsang.farm/v2/chart/smartfarm-environment?smartFarmID=${smartFarmID}&fromTime=${fromTime.toISOString()}&endTime=${endTime.toISOString()}`,
      { headers: { authorization: `Bearer ${accessToken}` } }
    )
    .then((response) => {
      const data = response.data.data as {
        time: string;
        co2: number;
        temperature: number;
        humidity: number;
      }[];
      data.sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
      );
      const res: {
        time: string;
        co2: number;
        temperature: number;
        humidity: number;
      }[] = [];
      for (let i = 0; i < data.length; i += 1) {
        const time = data[i].time.split(':')[0];
        if (!flag[time]) {
          if (
            data[i].temperature !== undefined &&
            data[i].temperature !== null &&
            data[i].humidity !== undefined &&
            data[i].humidity !== null &&
            data[i].co2 !== undefined &&
            data[i].co2 !== null
          ) {
            flag[time] = true;
            data[i].time = new Date(`${time}:00:00Z`).toISOString();
            res.push(data[i]);
          }
        }
      }
      return res;
    });

  flag = {};
  const nuData = await axios
    .get(
      `https://iot-api.sangsang.farm/v2/chart/ec-ph?deviceID=${igDeviceID}&fromTime=${fromTime.toISOString()}&endTime=${endTime.toISOString()}`,
      { headers: { authorization: `Bearer ${accessToken}` } }
    )
    .then((response) => {
      const data = response.data.data as {
        time: string;
        waterTemperature: number;
        ec: number;
        ph: number;
      }[];
      data.sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
      );
      const res: {
        time: string;
        waterTemperature: number;
        ec: number;
        ph: number;
      }[] = [];
      for (let i = 0; i < data.length; i += 1) {
        const time = data[i].time.split(':')[0];
        if (!flag[time]) {
          if (
            data[i].waterTemperature !== undefined &&
            data[i].waterTemperature !== null &&
            data[i].ec !== undefined &&
            data[i].ec !== null &&
            data[i].ph !== undefined &&
            data[i].ph !== null
          ) {
            flag[time] = true;
            data[i].time = new Date(`${time}:00:00Z`).toISOString();
            res.push(data[i]);
          }
        }
      }
      return res;
    });

  for (let i = 0; i < envData.length; i += 1) {
    for (let j = 0; j < nuData.length; j += 1) {
      if (envData[i].time === nuData[j].time) {
        result.push({ ...envData[i], ...nuData[j] });
        break;
      }
    }
  }

  for (let i = 0; i < result.length; i += 1) {
    const time = new Date(result[i].time);
    time.setHours(time.getHours() + 9);

    result[i].time = time.toISOString().replace('T', ' ').replace('.000Z', '');
  }

  // Blob 생성
  const blob = new Blob([jsonToCsv(result)!], {
    type: 'text/csv;charset=utf-8;',
  });
  const url = URL.createObjectURL(blob);

  // 링크 생성
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName || 'data'}.csv`);
  document.body.appendChild(link);

  // 다운로드 시작
  link.click();

  // 다운로드 후 링크 제거
  document.body.removeChild(link);
};

export default getData;
