/* eslint-disable @typescript-eslint/explicit-function-return-type  */
/* eslint-disable react/no-unescaped-entities */
import { ChangeEvent, useState } from 'react';
import './App.css';
// import { Button } from 'react-day-picker';
// import getData from './utils/getData';
import { Button, Dialog, Input, Spinner } from '@material-tailwind/react';
import DatePicker from './DatePicker';
import getData from './utils/getData';

function App() {
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [fileName, setFileName] = useState<string | null>(null);
  const [isWaitDownload, setIsWaitDownload] = useState(false);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="p-4 text-center text-2xl font-bold">
          스마트팜 환경 데이터 다운로드
        </div>
        <div className="w-[20rem] py-4">
          <DatePicker
            label="데이터 검색 시작 시간"
            setDate={setFromTime}
            date={fromTime}
          />
        </div>
        <div className="w-[20rem] py-4">
          <DatePicker
            label="데이터 검색 종료 시간"
            setDate={setToTime}
            date={toTime}
          />
        </div>
        <div className="w-[20rem]">
          <Input
            label="파일 명"
            value={fileName || ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFileName(event.target.value);
            }}
          />
        </div>
        <Button
          className="my-4 w-[20rem] text-xl font-bold"
          onClick={async () => {
            setIsWaitDownload(true);
            await getData(
              30,
              'IG100-0032',
              fromTime,
              toTime,
              fileName || '스마트팜 환경 데이터'
            );
            setIsWaitDownload(false);
          }}
        >
          다운 로드
        </Button>
      </div>
      <Dialog open={isWaitDownload} handler={() => {}}>
        <div className="flex flex-col items-center justify-center gap-4 p-4">
          <div className="text-center text-xl font-bold text-black">
            파일 다운로드 중
          </div>
          <div className="text-center text-lg font-bold text-black">
            파일 명 : {fileName || '스마트팜 환경 데이터'}.csv
          </div>
          <Spinner className="h-10 w-10" />
        </div>
      </Dialog>
    </div>
  );
}

export default App;
